'use client'

import {
  Box,
  Heading,
  VStack,
  Button,
  SimpleGrid,
  useColorModeValue
} from '@chakra-ui/react'

export default function Faqs() {
  return (
    <Box
      // border="2px solid red"
      bg={useColorModeValue('gray.100', 'gray.900')}
      w={"100%"}
      id='demo-interactive'
    >
      <Box 
        p={12} 
        mt={20} 
        mb={20}
      >
        <VStack spacing={2} textAlign="center">
          <Heading as="h1" size='2xl'>
            Have Questions?
          </Heading>
          <SimpleGrid 
          columns={{base:1, md:2}} 
        //   border={"2px solid red"}
          minW={"80%"}
          >
            <Box
            // border="2px solid red"
            textAlign={"left"}
            marginLeft={{md: 40}}
            >
            <Heading 
                size={"lg"}
                pt={{base: 5, md: 20}}
                pb={{base: 5, md: 20}}
            >
                Want to learn more? See our FAQs
            </Heading>
            </Box>
            <Box
            // border="2px solid red"
            display="flex"
            justifyContent="center"
            alignItems="center"
            >
            <Button colorScheme='teal' 
            size={{md:"lg"}} width={{base: "100%", md: "50%"}} height={{base: "200%",md: "30%"}} 
            fontSize={"xl"}
            as={"a"}
            href='/faqs'
            >
                FAQs
            </Button>
            </Box>
          </SimpleGrid>
        </VStack>
      </Box>
    </Box>
  )
}
