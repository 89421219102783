'use client'

import { Box, SimpleGrid, Image, Text, Stack, Flex, Heading } from '@chakra-ui/react'

interface FeatureProps {
  title: string
  // text: string
  imageSrc: string
}

// const Feature = ({ title, text, imageSrc }: FeatureProps) => {
const Feature = ({ title, imageSrc }: FeatureProps) => {
  return (
    <Stack align={"center"}>
      <Flex
        w={24}
        h={24}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        // bg={'gray.100'}
        mb={1}>
        <Image src={imageSrc} alt={title} boxSize="full" borderRadius="full" />
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      {/* <Text color={'gray.600'}>{text}</Text> */}
    </Stack>
  )
}

export default function Benefits() {
  return (
    <Box 
    // border="2px solid red"
    id='benefits'
    >
    <Box 
      p={10} 
      // mr={200}
      mr={{ base: '10', md: '200'}}
      ml={{ base: '10', md: '200'}} 
      // ml={200} 
      mt={20} 
      mb={20}>
      <Heading mb={70} size='2xl'>Why JudgeAI is better than human judge?</Heading>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
        <Feature
          imageSrc={'images/benefit_1.png'}
          title={'Robust and tested legal algorithms'}
          // text={
          //   'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          // }
        />
        <Feature
          imageSrc={'/images/benefit_2.svg'}
          title={'Quick and accurate Judgements'}
          // text={
          //   'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          // }
        />
        <Feature
          imageSrc={'/images/benefit_3_alt.png'}
          title={'Evidence analysis using multimodal AI'}
          // text={
          //   'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          // }
        />
        <Feature
          imageSrc={'/images/benefit_4.png'}
          title={'Multi-jurisdictional adaptability'}
          // text={
          //   'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          // }
        />
      </SimpleGrid>
    </Box>
    </Box>
  )
}