import {
    ChakraProvider,
    Box,
    VStack,
    Grid,
    theme,
    Heading,
    Text,
    IconButton,
    HStack,
  } from "@chakra-ui/react"
  import { ArrowBackIcon } from "@chakra-ui/icons"
  
  export const DisclaimerPage = () => (
    <ChakraProvider theme={theme}>
      <Grid minH="100vh" p={0} justifyContent="center">
        <Box w={["90vw", "80vw", "70vw"]} mt={10}>
          <VStack spacing={4}>
            <HStack w="full" alignItems={"center"}>
              <IconButton
                as="a"
                href="/"
                icon={<ArrowBackIcon />}
                aria-label="Back to home"
                variant="ghost"
                size="lg"
              />
              <Heading as="h1" size="2xl">
                Disclaimer
              </Heading>
            </HStack>
            <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
              1. No Legal Advice
            </Heading>
            <Text w={"100%"}>
              The information provided by JudgeAI is for general informational purposes only and should not be 
              construed as legal advice. Use of our Services does not create an attorney-client relationship 
              between you and JudgeAI or any of its representatives. For legal advice tailored to your specific 
              situation, please consult a licensed attorney.
            </Text>
            <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
              2. Accuracy of Information
            </Heading>
            <Text w={"100%"}>
              While we strive to provide accurate and up-to-date information, JudgeAI makes no representations 
              or warranties of any kind, express or implied, about the completeness, accuracy, reliability, 
              suitability, or availability of the information, products, services, or related graphics contained in our 
              Services for any purpose. Any reliance you place on such information is strictly at your own risk.
            </Text>
            <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
              3. Limitation of Liability
            </Heading>
            <Text w={"100%"}>
              In no event will JudgeAI, its affiliates, or their respective officers, directors, employees, agents, 
              suppliers, or licensors be liable for any direct, indirect, incidental, special, consequential, or 
              punitive damages, including without limitation, damages for loss of profits, revenue, data, or use, 
              incurred by you or any third party, whether in an action in contract, tort (including negligence), 
              breach of statutory duty, or otherwise, arising from or related to the use of our Services, even if 
              JudgeAI has been advised of the possibility of such damages.
            </Text>
            <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
              4. Third-Party Links
            </Heading>
            <Text w={"100%"}>
              Our Services may contain links to third-party websites or services that are not owned or controlled 
              by JudgeAI. We have no control over, and assume no responsibility for, the content, privacy policies, 
              or practices of any third-party websites or services. You acknowledge and agree that JudgeAI shall 
              not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be 
              caused by or in connection with the use of or reliance on any such content, goods, or services 
              available on or through any such websites or services.
            </Text>
            <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
              5. Use of AI Technology
            </Heading>
            <Text w={"100%"}>
              JudgeAI utilizes artificial intelligence technology, including the GPT-4 language model developed 
              by OpenAI, to assist in providing responses and analyses. While we strive to ensure that our AI 
              systems are accurate and reliable, the outputs generated by these systems are based on patterns 
              in data and may not always reflect current laws, regulations, or specific circumstances. Users are 
              advised to verify any information provided by our AI systems with qualified legal professionals.
            </Text>
            <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
              6. Changes to the Disclaimer
            </Heading>
            <Text w={"100%"}>
              JudgeAI reserves the right to modify this Disclaimer at any time. We will notify you of any changes 
              by posting the new Disclaimer on our website. You are advised to review this Disclaimer periodically 
              for any changes. Changes to this Disclaimer are effective when they are posted on this page.
            </Text>
            <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
              7. Contact Us
            </Heading>
            <Text w={"100%"}>
              If you have any questions about this Disclaimer, please contact us at: info@judgeai.space.
            </Text>
          </VStack>
        </Box>
      </Grid>
    </ChakraProvider>
  )
  