import {
    Box,
    VStack,
    Grid,
  } from "@chakra-ui/react"
import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Benefits from "../components/Benefits"
import Working from "../components/Working"
import Usecases from "../components/Usecases"
import Team from "../components/Team"
import Footer from "../components/Footer"
import Partnerships from "../components/Partnerships"
import Demo from "../components/Demo"
import Faqs from "../components/Faqs"

export const HomePage = () => (
    <Box>
        <Navbar />
      <Box 
      textAlign="center" 
      fontSize="xl"
      // border="2px solid red"
      >
        <Grid minH="100vh" p={0}>
          <VStack 
          spacing={0} 
          // border="2px solid red"
          >
            <Hero />
            <Partnerships />
            <Benefits />
            <Working />
            <Usecases />
            <Team />
            <Demo />
            <Faqs />
            <Footer />
          </VStack>
        </Grid>
      </Box>
    </Box>
  )