'use client'

import {
  Box,
  Stack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Card,
  CardBody,
  Image,
} from '@chakra-ui/react'


export default function Team() {
  return (
    <Box
    // border="2px solid red"
    id='team'
    w={"100%"}
    bg={useColorModeValue('gray.100', 'gray.900')}
    >
    <Box 
    p={12}
    mt={20} 
    mb={20}
    >
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" size='2xl'>
          Leadership Team
        </Heading>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 20 }}
        py={10}>
            <Card maxW='xs' variant={"unstyled"} bg={useColorModeValue('gray.100', 'gray.900')}>
              <CardBody>
                <Image
                  src='images/yuri.jpeg'
                  alt='Yuri Kozlov'
                  borderRadius='full'
                />
                <Stack mt='6' spacing='3'>
                    <Text fontSize='2xl'>Yuri Kozlov - CEO</Text>
                    <Text
                    fontSize='md'
                    >
                    Yuri, a seasoned lawyer with a proven track record, serves as the legal mind behind the algorithms that form the backbone of JudgeAI.
                    </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW='xs' variant={"unstyled"} bg={useColorModeValue('gray.100', 'gray.900')}>
              <CardBody>
                <Image
                  src='images/taaha.jpeg'
                  alt='Taaha Bajwa'
                  borderRadius='full'
                />
                <Stack mt='6' spacing='3'>
                    <Text fontSize='2xl'>Taaha Bajwa - CTO</Text>
                    <Text
                    fontSize='md'
                    >
                    Taaha, an experienced AI developer, orchestrates training, deployment and monitoring of the advanced AI workflows that power JudgeAI.
                    </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW='xs' variant={"unstyled"} bg={useColorModeValue('gray.100', 'gray.900')}>
              <CardBody>
                <Image
                  src='images/maria.jpeg'
                  alt='Maria Shutova'
                  borderRadius='full'
                />
                <Stack mt='6' spacing='3'>
                    <Text fontSize='2xl'>Maria Shutova - CPO</Text>
                    <Text
                    fontSize='md'
                    >
                    Maria, with a rich pedigree in sales and customer success, spearheads JudgeAI's client acquisition and relationship management.
                    </Text>
                </Stack>
              </CardBody>
            </Card>
      </Stack>
    </Box>
    </Box>
  )
}