// 'use client'

// import {
//   Box,
//   useColorModeValue,
//   Image,
//   Center,
// } from '@chakra-ui/react'

// export default function Partnerships() {
//   return (
//     <Box w={"100%"} bg={useColorModeValue('gray.200', 'gray.200')}>
//         <Center h='150px'>
//             <Image src='microsoft_partner.png' alt='Partnership with microsoft' h={"100px"} />
//         </Center>
//     </Box>
//   )
// }


// 'use client'

// import {
//   Box,
//   useColorModeValue,
//   Image,
//   Center,
//   Text
// } from '@chakra-ui/react'

// export default function Partnerships() {
//   return (
//     <Box w={"100%"} bg={useColorModeValue('gray.200', 'gray.200')} p={4}>
//       <Text fontSize='2xl' fontWeight='bold' mb={1} textAlign='center'>
//         Our Partners
//       </Text>
//       <Center h='100px' border="2px solid red">
//         {/* <Image src='microsoft_partner.png' alt='Partnership with Microsoft' h={"100px"}  border="2px solid red"/> */}
//         <Image src='partners.png' alt='Partnership with Microsoft' h={"100px"}  border="2px solid red"/>
//       </Center>
//     </Box>
//   )
// }


'use client'

import {
  Box,
  useColorModeValue,
  Image,
  Center,
  Text,
  SimpleGrid
} from '@chakra-ui/react'

export default function Partnerships() {
  return (
    <Box w={"100%"} bg={useColorModeValue('gray.200', 'gray.200')} p={4}>
      <Text fontSize='2xl' fontWeight='bold' mb={4} textAlign='center' color="black" >
        Our Partners
      </Text>
      <SimpleGrid columns={[1, null, 2]} spacing={4} alignItems='center'>
        <Center>
          <Image src='micr_partner.png' alt='Partnership with Microsoft' h={"100px"}/>
        </Center>
        <Center>
          <Image src='in5_partner.png' alt='Partnership with Another Partner' h={"100px"}/>
        </Center>
      </SimpleGrid>
    </Box>
  )
}

