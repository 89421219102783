// import { ReactNode } from 'react';
// import {
//   Box,
//   Flex,
//   HStack,
//   Link,
//   IconButton,
//   Button,
//   useDisclosure,
//   useColorModeValue,
//   // Stack,
//   Image,
//   LinkBox,
//   LinkOverlay,
//   VStack,
// } from '@chakra-ui/react';
// import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
// import { ColorModeSwitcher } from '../ColorModeSwitcher';

// const Links = [
//   { name: 'Benefits', id: 'benefits' },
//   { name: 'How it works', id: 'how-it-works' },
//   { name: 'Use Cases', id: 'use-cases' },
//   { name: 'Team', id: 'team' },
//   // { name: 'Demo', id: 'demo-interactive' },
// ];

// const scrollToSection = (id: string) => {
//   const element = document.getElementById(id);
//   if (element) {
//     element.scrollIntoView({ behavior: 'smooth' });
//   }
// };

// const NavLink = ({ children, to }: { children: ReactNode, to: string }) => (
//   <Link
//     px={2}
//     py={1}
//     rounded={'md'}
//     _hover={{
//       textDecoration: 'none',
//       bg: useColorModeValue('gray.200', 'gray.700'),
//     }}
//     onClick={() => scrollToSection(to)}
//   >
//     {children}
//   </Link>
// );

// export default function Navbar() {
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   return (
//     <>
//       <Box
//         bg={useColorModeValue('gray.100', 'gray.900')}
//         px={4}
//         position="sticky"
//         top="0"
//         zIndex="1000"
//         // border="2px solid red"
//       >
//         <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
//           <IconButton
//             size={'md'}
//             icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
//             aria-label={'Open Menu'}
//             display={{ md: 'none' }}
//             onClick={isOpen ? onClose : onOpen}
//           />
//           <HStack spacing={8} alignItems={'center'}>
//             <Box>
//               <LinkBox onClick={() => scrollToSection('demo')} cursor="pointer">
//                 <LinkOverlay>
//                   <Image src={useColorModeValue("navbar_logo.png", "navbar_logo_darkmode.png")} width={"120px"} />
//                 </LinkOverlay>
//               </LinkBox>
//             </Box>
//           </HStack>
//           <Flex alignItems={'center'}>
//             <HStack spacing='24px' display={{ base: 'none', md: 'flex' }}>
//             <HStack
//               as={'nav'}
//               spacing={4}
//               display={{ base: 'none', md: 'flex' }}
//             >
//               {Links.map((link) => (
//                 <NavLink key={link.id} to={link.id}>{link.name}</NavLink>
//               ))}
//             </HStack>
//               <ColorModeSwitcher />
//               <Button
//                 variant={'solid'}
//                 colorScheme='teal'
//                 as={"a"}
//                 href='https://portal.judgeai.space/judgement_demo'
//               >
//                 View Demo
//               </Button>
//               <Button
//                 variant={'solid'}
//                 colorScheme='teal'
//                 as={"a"}
//                 href='https://portal.judgeai.space/'
//               >
//                 Use JudgeAI
//               </Button>
//             </HStack>
//           </Flex>
//         </Flex>

//         {isOpen ? (
//           <Box pb={4} display={{ md: 'none' }}>
//             <VStack as={'nav'} spacing={4}>
//               {Links.map((link) => (
//                 <NavLink key={link.id} to={link.id}>{link.name}</NavLink>
//               ))}
//               <ColorModeSwitcher />
//               <Button
//                 variant={'solid'}
//                 colorScheme='teal'
//                 as={"a"}
//                 href='https://portal.judgeai.space/judgement_demo'
//                 width={"full"}
//               >
//                 View Demo
//               </Button>
//               <Button
//                 variant={'solid'}
//                 colorScheme='teal'
//                 as={"a"}
//                 href='https://portal.judgeai.space/'
//                 width="full"
//               >
//                 Use JudgeAI
//               </Button>
//             </VStack>
//           </Box>
//         ) : null}
//       </Box>
//     </>
//   );
// }


import { ReactNode } from 'react';
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Image,
  LinkBox,
  LinkOverlay,
  VStack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from '../ColorModeSwitcher';

const Links = [
  { name: 'Benefits', id: 'benefits', isExternal: false },
  { name: 'How it works', id: 'how-it-works', isExternal: false },
  { name: 'Use Cases', id: 'use-cases', isExternal: false },
  { name: 'Team', id: 'team', isExternal: false },
  { name: 'Blog', id: '/blog', isExternal: true }, // Add the blog link here
];

const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const NavLink = ({ children, to, isExternal }: { children: ReactNode, to: string, isExternal: boolean }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={isExternal ? to : undefined}
    onClick={isExternal ? undefined : () => scrollToSection(to)}
    isExternal={isExternal}
  >
    {children}
  </Link>
);

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        bg={useColorModeValue('gray.100', 'gray.900')}
        px={4}
        position="sticky"
        top="0"
        zIndex="1000"
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <LinkBox onClick={() => scrollToSection('demo')} cursor="pointer">
                <LinkOverlay>
                  <Image src={useColorModeValue("navbar_logo.png", "navbar_logo_darkmode.png")} width={"120px"} />
                </LinkOverlay>
              </LinkBox>
            </Box>
          </HStack>
          <Flex alignItems={'center'}>
            <HStack spacing='24px' display={{ base: 'none', md: 'flex' }}>
              <HStack
                as={'nav'}
                spacing={4}
                display={{ base: 'none', md: 'flex' }}
              >
                {Links.map((link) => (
                  <NavLink key={link.id} to={link.id} isExternal={link.isExternal}>
                    {link.name}
                  </NavLink>
                ))}
              </HStack>
              <ColorModeSwitcher />
              <Button
                variant={'solid'}
                colorScheme='teal'
                as={"a"}
                href='https://portal.judgeai.space/judgement_demo'
              >
                View Demo
              </Button>
              <Button
                variant={'solid'}
                colorScheme='teal'
                as={"a"}
                href='https://portal.judgeai.space/'
              >
                Use JudgeAI
              </Button>
            </HStack>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <VStack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link.id} to={link.id} isExternal={link.isExternal}>
                  {link.name}
                </NavLink>
              ))}
              <ColorModeSwitcher />
              <Button
                variant={'solid'}
                colorScheme='teal'
                as={"a"}
                href='https://portal.judgeai.space/judgement_demo'
                width={"full"}
              >
                View Demo
              </Button>
              <Button
                variant={'solid'}
                colorScheme='teal'
                as={"a"}
                href='https://portal.judgeai.space/'
                width="full"
              >
                Use JudgeAI
              </Button>
            </VStack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
