import {
    ChakraProvider,
    Box,
    VStack,
    Grid,
    theme,
    Heading,
    Text,
    HStack,
    IconButton,
  } from "@chakra-ui/react"
  import { ArrowBackIcon } from "@chakra-ui/icons"
  
  export const ArbitrationRulesPage = () => (
    <ChakraProvider theme={theme}>
      <Grid minH="100vh" p={0} justifyContent="center">
        <Box w={["90vw", "80vw", "70vw"]} mt={10}>
          <VStack spacing={4}>
              <HStack w="full" alignItems={"center"}>
                <IconButton
                  as="a"
                  href="/"
                  icon={<ArrowBackIcon />}
                  aria-label="Back to home"
                  variant="ghost"
                  size="lg"
                />
                <Heading as="h1" size="2xl">
                    JudgeAI Arbitration Rules
                </Heading>
              </HStack>
            <Text w={"100%"} textAlign={"left"}>
              Effective Date: 20 October, 2024
            </Text>
            <Box mb={10}>
        <Heading as="h2" size="xl">
          1. General Provisions
        </Heading>
        <Box>
            <Heading as="h3" size="l" mb={0}>
              1.1. Application of the Arbitration Rules
            </Heading>
            <Text>
              These Arbitration Rules (hereinafter referred to as the "Rules") regulate the
              procedure for arbitration proceedings conducted using the JudgeAI platform, which is
              based on a combination of artificial intelligence (hereinafter referred to as "AI")
              technologies and complex legal algorithms, allowing for the full automation of
              evidence analysis and decision-making. The JudgeAI platform is applied in cases where
              the parties have agreed to arbitration using the platform in the corresponding
              arbitration agreement.
            </Text>
          </Box>

          {/* 1.2 Binding Nature of the Rules */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.2. Binding Nature of the Rules
            </Heading>
            <Text>
              1.2.1. Proceedings conducted using JudgeAI are subject to the provisions of these
              Rules, as well as the terms of the parties' arbitration agreement, unless otherwise
              provided by the agreement.
            </Text>
            <Text>
              1.2.2. In the event of any conflict between these Rules and the arbitration agreement,
              the provisions of the arbitration agreement shall prevail, provided that their
              application does not violate mandatory legal norms.
            </Text>
            <Text>
              1.2.3. The JudgeAI platform may be used for the resolution of commercial, civil, and
              other disputes unless otherwise provided by applicable law or the arbitration
              agreement.
            </Text>
          </Box>

          {/* 1.3 Independence and Impartiality of the Arbitrator */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.3. Independence and Impartiality of the Arbitrator
            </Heading>
            <Text>
              1.3.1. The JudgeAI platform acts as an independent and impartial arbitrator. The
              platform applies built-in legal algorithms to analyze the evidence and legal arguments
              presented by the parties, excluding any possibility of external influence or bias.
            </Text>
            <Text>
              1.3.2. Decisions made by JudgeAI are based on the data provided by the parties and the
              relevant legal norms, ensuring objectivity and accuracy of the decisions rendered.
            </Text>
            <Text>
              1.3.3. The platform’s legal algorithms are used to evaluate evidence and arguments,
              taking into account the facts, law, and applicable norms, thereby excluding the human
              factor and minimizing the risk of errors.
            </Text>
          </Box>

          {/* 1.4 Electronic Nature of Arbitration */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.4. Electronic Nature of Arbitration
            </Heading>
            <Text>
              1.4.1. All arbitration procedures conducted using JudgeAI are carried out exclusively
              in electronic format.
            </Text>
            <Text>
              1.4.2. Documents, evidence, and statements are submitted by the parties exclusively in
              digital form through the secure JudgeAI platform, ensuring transparency and
              accessibility throughout all stages of the proceedings.
            </Text>
            <Text>
              1.4.3. The platform automatically processes the submitted documents, analyzes the
              evidence, and renders a decision, thereby eliminating the need for personal
              participation of arbitrators or the holding of hearings, unless otherwise provided by
              the arbitration agreement.
            </Text>
          </Box>

          {/* 1.5 Legal Algorithms and Their Application */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.5. Legal Algorithms and Their Application
            </Heading>
            <Text>
              1.5.1. All actions performed by the JudgeAI platform, including the analysis of
              evidence, evaluation of legal arguments, and decision-making, are carried out using
              legal algorithms specifically designed to automate the dispute resolution process.
            </Text>
            <Text>
              1.5.2. The legal algorithms automatically compare the presented evidence with legal
              requirements and applicable law. This includes assessing the authenticity, relevance,
              and weight of the evidence.
            </Text>
            <Text>
              1.5.3. The algorithms also apply economic and mathematical models to calculate
              compensation or other forms of legal liability, where applicable, in accordance with
              the subject matter of the dispute and the parties' agreements.
            </Text>
            <Text>
              1.5.4. Decisions made by JudgeAI include detailed justifications with references to
              the legal facts, evidence, and legal norms on which the decision is based.
            </Text>
          </Box>

          {/* 1.6 Principles of Transparency and Accountability */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.6. Principles of Transparency and Accountability
            </Heading>
            <Text>
              1.6.1. All procedures conducted through JudgeAI are carried out in accordance with the
              principle of transparency. This means that all actions taken by the platform are
              recorded and accessible to the parties through the system interface.
            </Text>
            <Text>
              1.6.2. JudgeAI automatically generates reports on each stage of the arbitration
              process, including evidence analysis, interim findings, and the final decision. These
              reports are available to the parties and may be used for subsequent analysis or appeal
              in cases provided by law.
            </Text>
          </Box>

          {/* 1.7 Full Automation of Decision-Making */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.7. Full Automation of Decision-Making
            </Heading>
            <Text>
              1.7.1. Once the parties have exchanged their final documents, the JudgeAI platform
              automatically begins analyzing the submitted materials and the parties' arguments.
            </Text>
            <Text>
              1.7.2. All decisions are rendered by the platform within 5-10 minutes after the
              completion of the exchange of documents and submission of all evidence.
            </Text>
            <Text>
              1.7.3. The decision includes a full justification with references to the facts, legal
              norms, and arguments used in rendering the decision. The decision is delivered to the
              parties electronically and is binding for enforcement, unless otherwise provided by
              the arbitration agreement or applicable law.
            </Text>
          </Box>

          {/* 1.8 Applicable Law and Arbitration Agreement */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.8. Applicable Law and Arbitration Agreement
            </Heading>
            <Text>
              1.8.1. Arbitration proceedings conducted using JudgeAI are carried out in accordance
              with the law specified in the parties' arbitration agreement. If the agreement does
              not specify the applicable law, JudgeAI applies the law most relevant to the substance
              of the dispute, taking into account the rules of private international law.
            </Text>
            <Text>
              1.8.2. The arbitration agreement must explicitly state the use of the JudgeAI platform
              as the arbitrator for resolving disputes between the parties. The agreement must be in
              writing and signed by the parties, or be part of a broader contract that includes an
              arbitration clause.
            </Text>
          </Box>

          {/* 1.9 Access to the Platform and User Roles */}
          <Box>
            <Heading as="h3" size="l" mb={0}>
              1.9. Access to the Platform and User Roles
            </Heading>
            <Text>
              1.9.1. Access to the JudgeAI platform is provided to the parties to the dispute and
              their representatives after completing the authentication procedure through a secure
              identification system.
            </Text>
            <Text>
              1.9.2. Each party may grant access to the platform to their lawyers, consultants, and
              other representatives, provided that each user is obligated to maintain confidentiality
              and use the platform only for the purposes stipulated in the arbitration agreement.
            </Text>
          </Box>


          <Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
  2. Key Components of Legal Algorithms and Artificial Intelligence
</Heading>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  2.1. Legal Algorithms
</Heading>
<Text w={"100%"}>
  JudgeAI utilizes specialized algorithms developed based on legal norms and principles. These algorithms enable the system to model the behavior of the parties in accordance with legal standards and assess the validity of claims and defenses presented. During the analysis, the system evaluates the legal obligations of the parties, potential breaches, and the factual circumstances of the case to determine the compliance of each argument with the applicable legal norms.
</Text>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  2.2. Evidence Analysis
</Heading>
<Text w={"100%"}>
  The system’s algorithms automatically assess the submitted evidence, classifying it by its level of objectivity and reliability. The platform analyzes legal documents, contracts, business correspondence, and other written materials to determine their relevance to the case and their alignment with the facts. The multi-level verification of evidence helps prevent the submission of inaccurate or contradictory information.
</Text>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  2.3. Economic-Mathematical Modeling
</Heading>
<Text w={"100%"}>
  In addition to legal analysis, JudgeAI employs economic-mathematical models to calculate fair compensation, damages, and other financial consequences related to violations. These models allow for an assessment of the economic risks arising from disputes and provide well-reasoned solutions regarding the financial obligations of the parties.
</Text>

<Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
  3. Confidentiality and Data Protection
</Heading>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  3.1. Confidentiality of Arbitration Proceedings
</Heading>
<Text w={"100%"}>
  All arbitration proceedings conducted through JudgeAI are strictly confidential. Access to case information and evidence is limited to the parties involved and the JudgeAI platform, unless otherwise stipulated in the parties' agreement. Confidentiality is maintained throughout all stages of the process, including the submission of evidence, its analysis, and the issuance of the decision.
</Text>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  3.2. Data Protection Measures
</Heading>
<Text w={"100%"}>
  The data provided by the parties during the proceedings is protected using encryption and other security measures in accordance with applicable data protection laws and standards. JudgeAI complies with the requirements outlined in its Privacy Policy, which governs the storage, processing, and protection of data during arbitration.
</Text>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  3.3. Limited Data Sharing
</Heading>
<Text w={"100%"}>
  The JudgeAI platform does not share data with third parties, except in cases explicitly required by law or the parties' agreement, and in accordance with the terms set forth in the Privacy Policy. This includes the protection of both personal data and procedural information related to the case.
</Text>


<Heading as="h2" size='xl' w={"100%"} textAlign={"left"}>
  4. Submission and Handling of Evidence
</Heading>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  4.1. Submission of Documents
</Heading>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.1.1. Filing of Claim by the Claimant
</Heading>
<Text w={"100%"}>
  a. The Claimant must submit the claim through the JudgeAI platform in electronic form. The claim must include a clear presentation of the claims, a list of facts supporting the claims, and the corresponding evidence.<br/>
  b. The attached evidence should not exceed 100 pages, with the exception of the contract text, which can be submitted separately.<br/>
  c. Upon submission of the claim, the JudgeAI platform automatically notifies the Respondent.
</Text>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.1.2. Respondent’s Defense Submission
</Heading>
<Text w={"100%"}>
  a. After receiving the claim, the Respondent must file their defense through the JudgeAI platform within 10 calendar days. The defense must clearly outline the Respondent’s objections, providing facts and evidence to dispute or diminish the Claimant’s claims.<br/>
  b. The defense submission should not exceed 100 pages and must include all necessary information for the Respondent’s defense, including references to facts, documents, and other supporting evidence.<br/>
  c. The platform automatically notifies the Claimant upon the Respondent’s submission of the defense.
</Text>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.1.3. Additional Submissions by Both Parties
</Heading>
<Text w={"100%"}>
  a. Following the submission of the defense, the Claimant has the right to provide additional written explanations within 5 calendar days. These explanations must not exceed 100 pages and should clarify, challenge, or reinforce the points made in the Respondent’s defense.<br/>
  b. The Respondent may also submit additional explanations within 5 calendar days after receiving the Claimant’s explanations. The Respondent’s additional submissions must also be limited to 100 pages and can contain further arguments or clarifications regarding the evidence previously submitted.
</Text>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  4.2. Evaluation of Evidence
</Heading>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.2.1. Automated Evidence Processing
</Heading>
<Text w={"100%"}>
  a. Once all documents have been submitted, JudgeAI automatically begins analyzing the evidence. The platform evaluates each piece of evidence based on its relevance, reliability, and factual alignment with the case circumstances.<br/>
  b. During the evaluation process, the system checks the origin and authenticity of each document, compares it with other submitted materials, and assesses its potential impact on the case outcome. The evidence is classified based on its importance to the case and its legal significance.
</Text>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.2.2. Cross-Analysis of Evidence from Both Parties
</Heading>
<Text w={"100%"}>
  a. JudgeAI analyzes evidence from both parties, identifying any contradictions or alignments between the facts presented. This helps to build an objective and comprehensive view of the dispute.<br/>
  b. The system also reviews the chronological sequence of the evidence to detect any inconsistencies in the data provided by the parties.
</Text>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.2.3. Criteria for Evaluating Evidence
</Heading>
<Text w={"100%"}>
  a. The platform assesses how much the presented evidence contributes to establishing legally significant facts.<br/>
  b. The authenticity and credibility of the evidence are evaluated, considering its source, potential for forgery, and overall trustworthiness.<br/>
  c. The system examines how the evidence fits within the overall timeline of events and whether it aligns with other materials submitted in the case.
</Text>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  4.3. Explanatory Documents
</Heading>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.3.1. Explanatory Documents by the Claimant
</Heading>
<Text w={"100%"}>
  a. The Claimant can submit additional written explanations after receiving the Respondent’s defense. These explanations must be submitted through the platform within 5 calendar days and should clarify, challenge, or further support the previously submitted evidence.<br/>
  b. The explanations should be structured clearly, and references to evidence and facts must be organized logically to facilitate JudgeAI's processing.
</Text>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.3.2. Explanatory Documents by the Respondent
</Heading>
<Text w={"100%"}>
  a. The Respondent may submit additional explanations within 5 calendar days after receiving the Claimant’s explanations. These documents must not exceed 100 pages and should contain explanations or new arguments concerning the previously submitted evidence.<br/>
  b. The Respondent can use these additional submissions to refute the Claimant’s positions, clarify their own arguments, or present further supporting evidence.
</Text>

<Heading as="h3" size='l' w={"100%"} textAlign={"left"}>
  4.4. Format and Structure of Documents
</Heading>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.4.1. Document Format Requirements
</Heading>
<Text w={"100%"}>
  a. All documents submitted through the JudgeAI platform must be in PDF or DOC formats to ensure uniformity and ease of processing.<br/>
  b. The documents must be clearly structured, with page numbers and references to attachments. Indexes and tables of contents are encouraged to help streamline the analysis and navigation of the documents.
</Text>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.4.2. Additional Materials
</Heading>
<Text w={"100%"}>
  a. If agreed upon by both parties, additional digital materials, such as images, tables, or charts supporting the arguments of the parties, may be submitted. These materials must be provided in a format compatible with the platform.<br/>
  b. Audio and video materials are not processed by the JudgeAI system unless expressly agreed upon by the parties in advance.
</Text>

<Heading as="h4" size='m' w={"100%"} textAlign={"left"}>
  4.4.3. Confidentiality Compliance
</Heading>
<Text w={"100%"}>
  All submitted documents are processed with strict confidentiality in accordance with the JudgeAI Privacy Policy. Access to the documents is limited to the parties involved and the platform unless otherwise agreed upon by the parties in the arbitration agreement.
</Text>

<Heading as="h2" size="xl" w="100%" textAlign="left">
  5. Process of Evidence Analysis
</Heading>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  5.1. Fully Automated Process
</Heading>
<Text w="100%">
  Upon the completion of the submission of all documents and evidence by the parties, the JudgeAI platform automatically initiates the analysis. This process is entirely automated, eliminating the need for human intervention, thus ensuring efficiency and impartiality in the review of the case. The platform evaluates all submitted materials for their relevance to the facts of the case and the applicable legal norms. During this process, evidence presented by both parties is compared, contradictions are identified, and consistency with the stated facts is verified.<br />
  At the initial stage, the system verifies the compliance of all submitted documents with the established requirements outlined in the arbitration agreement and the rules. Evidence that does not meet these standards may be excluded from consideration. Subsequently, each document is analyzed in terms of its legal significance and reliability. The platform’s algorithms give particular attention to the chronology of events and the interrelationship of the submitted evidence.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  5.2. Decision Rendering
</Heading>
<Text w="100%">
  Once the analysis of the evidence is complete, the JudgeAI platform automatically renders a decision. This occurs within 5-10 minutes following the submission and analysis of all documents and evidence. The decision includes a detailed description of the case’s factual circumstances, the legal reasoning supporting the decision, and the actions required by the parties. These actions may include, for instance, compensation payments, the fulfillment of contractual obligations, or other prescribed remedies.<br />
  The decision is delivered to the parties electronically via the JudgeAI platform. It is deemed final and binding unless otherwise stipulated in the arbitration agreement.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  5.3. Binding Nature and Enforcement of the Decision
</Heading>
<Text w="100%">
  Upon receipt of the decision, the parties are obligated to comply with its terms within the specified timeframes. The JudgeAI platform tracks the receipt of the decision by the parties and monitors its enforcement. Should a party encounter difficulties in complying with or refuse to execute the decision, the system may initiate additional measures in accordance with the provisions of the arbitration agreement.
</Text>

<Heading as="h2" size="xl" w="100%" textAlign="left">
  6. Procedural Deadlines
</Heading>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  6.1. Submission Deadlines
</Heading>
<Text w="100%">
  The parties are required to adhere to the deadlines for the submission of documents as specified in these Rules, unless otherwise agreed upon in the arbitration agreement. The deadlines are established to ensure the efficient and timely resolution of disputes through the JudgeAI platform.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  6.2. Extension Requests
</Heading>
<Text w="100%">
  In cases where it becomes necessary to extend these deadlines, the parties may submit a request for an extension via the JudgeAI platform. JudgeAI will independently evaluate such requests based on objective reasons and the specific circumstances of the case. The platform reserves the right to grant or deny the extension. Extensions are generally granted only in exceptional cases, where the requesting party can demonstrate the need for additional time due to unforeseeable and justified circumstances, such as technical issues or new evidence discovery.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  6.3. Consequences of Non-Compliance
</Heading>
<Text w="100%">
  Failure to comply with the established deadlines without a granted extension may result in the exclusion of documents from the case file or other procedural consequences as deemed appropriate by JudgeAI.
</Text>

<Heading as="h2" size="xl" w="100%" textAlign="left">
  7. Enforcement of the Decision
</Heading>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  7.1. Binding Nature
</Heading>
<Text w="100%">
  The decision rendered by JudgeAI is legally binding upon the parties and holds full legal force in accordance with the applicable laws governing arbitration proceedings. The parties are obligated to fulfill all conditions set forth in the arbitration decision, including, but not limited to, payment of compensation, performance of contractual obligations, or any other actions mandated by the decision.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  7.2. Compliance and Judicial Enforcement
</Heading>
<Text w="100%">
  The parties must comply with the ruling within the timeframe specified in the decision. Non-compliance or failure to fulfill the obligations outlined in the decision may lead to enforcement through judicial measures. The parties may seek enforcement of the arbitration award in a court of law in accordance with international arbitration law, such as the New York Convention on the Recognition and Enforcement of Foreign Arbitral Awards.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  7.3. Responsibility for Enforcement
</Heading>
<Text w="100%">
  JudgeAI does not monitor or participate in the enforcement process after the decision has been issued. It is the responsibility of the parties to initiate legal actions to enforce the decision if voluntary compliance is not forthcoming.
</Text>

<Heading as="h2" size="xl" w="100%" textAlign="left">
  8. Final Provisions
</Heading>

<Heading as="h4" size="sm" w="100%" textAlign="left">
  8.1. Responsibility for Evidence Accuracy
</Heading>
<Text w="100%">
  JudgeAI disclaims any responsibility for errors or inaccuracies arising from the submission of false or misleading evidence by the parties. The accuracy and authenticity of the materials submitted during arbitration are solely the responsibility of the parties.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  8.2. Right to Amend Rules
</Heading>
<Text w="100%">
  JudgeAI reserves the right to amend these Rules at its discretion. Any amendments shall take effect immediately upon their publication on the JudgeAI platform. These changes will apply to new arbitration cases registered after the amendments, while ongoing cases will continue under the Rules in effect at the time of their commencement.
</Text>

<Heading as="h5" size="sm" w="100%" textAlign="left">
  8.3. Procedural Obligations
</Heading>
<Text w="100%">
  The parties are obligated to perform all procedural actions in accordance with these Rules, including the timely submission of documents and adherence to all prescribed deadlines. Failure to comply with these obligations may result in procedural sanctions, such as the exclusion of evidence or other remedies as determined by JudgeAI.
</Text>



            </Box>
          </VStack>
        </Box>
      </Grid>
    </ChakraProvider>
  )
  