'use client'

import {
  Box,
  Stack,
  Heading,
  Text,
  VStack,
  Card,
  CardBody,
  Image,
} from '@chakra-ui/react'


export default function Usecases() {
  return (
    <Box
    // border="2px solid red"
    w={"100%"}
    id='use-cases'
    >
    <Box 
    p={12} 
    mt={20} 
    mb={20}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" size='2xl'>
          Applications of JudgeAI
        </Heading>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}>
            <Card maxW='xs'>
              <CardBody>
                <Image
                  src='images/homepage_1.webp'
                  alt='automated judicial processing'
                  borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Text fontSize='2xl' textAlign={"left"}>Automated Judicial Processing</Text>
                    <Text
                    fontSize='md'
                    textAlign={"left"}
                    >
                    Automates procedural and administrative tasks within the judicial process, promoting consistent and unbiased case management.
                    </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW='xs'>
              <CardBody>
                <Image
                  src='images/homepage_2.webp'
                  alt='Litigation Perspective Analysis'
                  borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Text fontSize='2xl' textAlign={"left"}>Litigation Perspective Analysis</Text>
                    <Text
                    fontSize='md'
                    textAlign={"left"}
                    >
                    Leverages proprietary AI workflows to analyze evidence and arguments, predicting case outcomes and guiding legal strategy.
                    </Text>
                </Stack>
              </CardBody>
            </Card>            
            <Card maxW='xs'>
              <CardBody>
                <Image
                  src='images/homepage_3.webp'
                  alt='Fair Settlement Calculation'
                  borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Text fontSize='2xl' textAlign={"left"}>Fair Settlement Calculation</Text>
                    <Text
                    fontSize='md'
                    textAlign={"left"}
                    >
                    Calculates fair settlements terms through an algorithmic interpretation of the legal framework and the specific details of the dispute.
                    </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card maxW='xs'>
              <CardBody>
                <Image
                src='images/homepage_4.webp'
                alt='codified law judgements'
                borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Text fontSize='2xl' textAlign={"left"}>Codified Legal Judgements</Text>
                    <Text
                    fontSize='md'
                    textAlign={"left"}
                    >
                    Streamlines the judicial process, guaranteeing fair and impartial case management that strictly adheres to codified law.
                    </Text>
                </Stack>
              </CardBody>
            </Card>
      </Stack>
    </Box>
    </Box>
  )
}