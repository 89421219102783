'use client'

import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  Container,
} from '@chakra-ui/react'


const Working = () => {
  return (
    <Box
    // border="2px solid red"
    id='how-it-works'
    w={"100%"}
    bg={useColorModeValue('gray.100', 'gray.900')}
    >
    <Container maxW={'7xl'} p="12" 
    // border="2px solid red"
    mt={20} 
    mb={20}
    >
      <Heading as="h1" size='2xl'>Journey of a case through JudgeAI</Heading>
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>JudgeAI consists of multiple stages</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            Our project is not merely about applying standard LLMs with prompts like "you are a judge, resolve this legal dispute." JudgeAI is a comprehensive project that includes unique legal algorithms specifically designed to evaluate evidence and make judicial decisions.
Our algorithms operate independently of pre-trained data. Pre-trained data is used solely to extract information from submitted documents, but all subsequent analysis and processing are carried out exclusively using these algorithms. This ensures the high accuracy and validity of legal conclusions made by the system.
The operation of the JudgeAI system consists of seven stages:
          </Text>
        </Box>
      </Box>
      {/* <Box mt={{ base: '8', sm: '12' }} /> */}
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="0%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>Analysis of the claim and evidence</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            JudgeAI starts with a preliminary check of the claim for formal validity. If the claim is initially deemed invalid, it is immediately rejected without moving to the next stage.
If the claim is considered valid, the system identifies all the evidence and facts relied upon by the claimant and proceeds to the next stage.
          </Text>
        </Box>
      </Box>
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="0%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>Request for the defendant's objections</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            After analyzing the claim, JudgeAI requests the defendant's objections. If the defendant's objections are initially deemed invalid, the claim is automatically granted without moving to the next stage.
If the objections are deemed valid, the system creates a counter-argument on the case and analyzes the objections, determining the need for additional evidence.
          </Text>
        </Box>
      </Box>
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="0%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>Process of clarifying the claim</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            If necessary, JudgeAI requests a clarified statement of claim or additions from the claimant.
This stage allows for clarification of claim details and preparation for forming the final case narrative.
          </Text>
        </Box>
      </Box>
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="0%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>Analysis of the defendant's additions</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            After receiving objections and clarifications, the system requests additional materials from the defendant, if necessary, to complete the case picture.
          </Text>
        </Box>
      </Box>
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="0%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>Formation of the case narrative</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            Using the collected facts and evidence, the system forms a complete case narrative, applying fuzzy logic to evaluate direct and indirect facts.
A detailed assessment of all circumstances is conducted, considering their interrelation and significance to the case, with facts evaluated by the strength of the evidence.
          </Text>
        </Box>
      </Box>
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="0%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>Modeling ideal behavior of the parties</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            JudgeAI analyzes how the parties should have behaved under ideal conditions, taking into account contract terms, business customs, and general legal principles, applying Nash equilibrium to evaluate possible behavior strategies.
          </Text>
        </Box>
      </Box>
      <Box mt={10}></Box>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="0%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }} textAlign={"left"}>
              <Heading>Formation of the final decision</Heading>
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Text
            as="p"
            marginTop="0"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            textAlign={"left"}
            >
            The actual narrative of the parties' behavior is compared with the ideal model. All deviations are identified and analyzed for their compliance with Nash equilibrium, alternative strategies, potential damages, and impact on contract terms.
The decision is documented with a full justification of each step, ensuring its transparency and comprehensibility for all parties.
As can be seen, the system does not use codified law or judicial precedents (although we have versions of algorithms that do use judicial precedents), which is extremely important as it makes JudgeAI independent even from lawmakers, i.e., from the state.
Additionally, it is important to note that JudgeAI works only with economic disputes; here, the concept of fairness is equated with Nash equilibrium, which cannot be done in non-economic disputes.
          </Text>
        </Box>
      </Box>
    </Container>
    </Box>
  )
}

export default Working