import {
    ChakraProvider,
    Box,
    VStack,
    Grid,
    theme,
    Heading,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    HStack,
    IconButton,
  } from "@chakra-ui/react"
  import { ArrowBackIcon } from "@chakra-ui/icons"
  
  export const FaqPage = () => (
    <ChakraProvider theme={theme}>
      <Grid minH="100vh" p={0} justifyContent="center">
        <Box w={["90vw", "80vw", "70vw"]} mt={10}>
          <VStack spacing={4} mb={20}>
              <HStack w="full" alignItems={"center"}>
                <IconButton
                  as="a"
                  href="/"
                  icon={<ArrowBackIcon />}
                  aria-label="Back to home"
                  variant="ghost"
                  size="lg"
                />
                <Heading as="h1" size="2xl">
                  FAQs
                </Heading>
              </HStack>
              </VStack>
              <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h3"} size='lg'>What is JudgeAI?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      JudgeAI is a system that automates judicial proceedings using artificial intelligence. It analyzes evidence, assesses the positions of the parties, and makes objective decisions based on legal algorithms. This makes the dispute resolution process faster, more transparent, and predictable.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h3"}  size='lg'>How does JudgeAI work?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      JudgeAI algorithms analyze documents and evidence using methods based on logic, economic-mathematical modeling, and legal dialectics. The general version of the system does not rely on codified laws but can be adapted for specific jurisdictions, such as working with the UAE Civil Code. It is important to note that JudgeAI currently works only with documentary text-based evidence.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h2"}  size='lg'>What problems does JudgeAI solve?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      JudgeAI can fully automate judicial proceedings. Additionally, it provides judges with second opinions to support decision-making, predicts the outcomes of legal cases, and assists in dispute resolution by calculating fair settlement terms.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h2"} size='lg'>What role does artificial intelligence play?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Artificial intelligence in JudgeAI is used to analyze evidence and model the behavior of parties during the process. However, the system relies on clearly defined legal algorithms, ensuring transparency and eliminating subjectivity in decision-making.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h2"} size='lg'>What are the advantages of using JudgeAI?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      The main advantages of the system include faster decision-making, accuracy, independence from human errors, and reduced costs for the parties involved and judicial authorities. Automation also helps to reduce the burden on the judicial system.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h2"} size='lg'>Can JudgeAI be used in different countries?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      JudgeAI’s algorithms can be adapted for any legal system. For instance, there is already a version that works based on the UAE Civil Code. We continue to expand the system's jurisdictional coverage.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h2"} size='lg'>Can JudgeAI replace judges?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      At this stage, JudgeAI is used as a support tool to provide second opinions to judges. Full replacement of judges is only possible in cases of lower complexity and after successful testing.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h2"} size='lg'>How is JudgeAI integrated into existing legal systems?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      JudgeAI can be integrated via API into existing legal platforms or used as a standalone solution for automated dispute resolution.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as='span' flex='1' textAlign='left'>
          <Heading as={"h2"} size='lg'>What are the future plans for JudgeAI?</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      We plan to expand the capabilities of JudgeAI by supporting new legal systems and enhancing the prediction of case outcomes and dispute resolution. We are also exploring possibilities to work with new types of evidence.
    </AccordionPanel>
  </AccordionItem>
</Accordion>


        </Box>
      </Grid>
    </ChakraProvider>
  )
  