'use client'

import { ReactNode } from 'react'
import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Image,
  HStack
} from '@chakra-ui/react'
import { MdEmail } from "react-icons/md";
import { FaWhatsapp, FaLinkedin } from "react-icons/fa";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  )
}

export default function Footer() {
  return (
    <Box
    w={"100%"}
    // border="2px solid red"
    // bg={useColorModeValue('gray.100', 'gray.900')}
    >
    <Box
      // bg={useColorModeValue('gray.50', 'gray.900')}
      // color={useColorModeValue('gray.700', 'gray.200')}
    //   border="2px solid red"
      minW={"70vw"}
      mt={20}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr 1fr 1fr', md: '2fr 2fr 2fr 2fr' }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Image width={"300px"} src={useColorModeValue("navbar_logo.png", "navbar_logo_darkmode.png")} mt={"-10"} />
            </Box>
            <Text align={"left"}>Completely independent and unbiased automated judicial system.</Text>
          </Stack>
          <Stack align={'right'}>
            <ListHeader>Navigation</ListHeader>
            <Box as="a" href={'#'}>
              Benefits
            </Box>
            <Box as="a" href={'#'}>
              How it works
            </Box>
            <Box as="a" href={'#'}>
              Use Cases
            </Box>
            <Box as="a" href={'#'}>
              Team
            </Box>
          </Stack>
          <Stack align={'right'}>
            <ListHeader>Terms and Policies</ListHeader>
            <Box as="a" href={'privacy-policy'}>
              Privacy Policy
            </Box>
            <Box as="a" href={'disclaimer'}>
              Disclaimer
            </Box>
            <Box as="a" href={'arbitration_rules'}>
              Arbitration Rules
            </Box>
          </Stack>
          <Stack textAlign={"left"} ml={10}>
            <ListHeader>Contact Us</ListHeader>
            <HStack>
              <MdEmail /> <Text>info@judgeai.space</Text>
            </HStack>
            <Box as="a" href={'https://www.linkedin.com/company/99514435'}>
              <HStack mt={3}>
                <FaLinkedin /> <Text>Official LinkedIn Page</Text>
              </HStack>
            </Box>
            <Box>
              <HStack mt={3}>
                <FaWhatsapp /> <Text>+374 91 063386</Text>
              </HStack>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
    </Box>
  )
}
