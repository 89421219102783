'use client'

import {
  Box,
  Heading,
  Image,
  Text,
  HStack,
  Tag,
  // SpaceProps,
  useColorModeValue,
  VStack,
  Grid,
  ChakraProvider,
  theme,
  IconButton,
  Link,
} from '@chakra-ui/react'
import { ArrowBackIcon } from "@chakra-ui/icons"

// interface IBlogTags {
//   tags: Array<string>
//   marginTop?: SpaceProps['marginTop']
// }

interface Props {
  marginTop?: number
  tags: any[]
}

const BlogTags = (props: Props) => {
  const { marginTop = 0, tags } = props

  return (
    <HStack spacing={2} marginTop={marginTop}>
      {tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="teal" key={tag}>
            {tag}
          </Tag>
        )
      })}
    </HStack>
  )
}

interface BlogAuthorProps {
  date: Date
  name: string
}

const BlogAuthor = (props: BlogAuthorProps) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="images/yuri.jpeg"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  )
}

const ArticleList = () => {
  return (
    <ChakraProvider theme={theme}>
    <Grid minH="100vh" p={0} justifyContent="center">
      <Box w={["90vw", "80vw", "70vw"]} mt={10}>
        <VStack spacing={4}>
          <HStack w="full" alignItems={"center"}>
            <IconButton
              as="a"
              href="/"
              icon={<ArrowBackIcon />}
              aria-label="Back to home"
              variant="ghost"
              size="lg"
            />
            <Heading as="h1" size="2xl">
              Blog
            </Heading>
          </HStack>
          <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={
                  // 'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                  "images/blog1.png"
                }
                alt="some good alt text"
                objectFit="contain"
              />
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(teal.600 1px, transparent 1px)',
                'radial(teal.300 1px, transparent 1px)',
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <BlogTags tags={['Scientific Article', "PDF"]} />
          <Heading marginTop="1">
            {/* <Text textDecoration="none" _hover={{ textDecoration: 'none' }}>
            JudgeAI and the Evolution of Court Processes
            </Text> */}
            {/* <Link href="https://your-link-here.com" textDecoration="none" _hover={{ textDecoration: 'none' }}>
      JudgeAI and the Evolution of Court Processes
    </Link> */}
    <Link 
  href="/files/ssrn-4961204.pdf" 
  download 
  textDecoration="none" 
  _hover={{ textDecoration: 'none' }}>
    JudgeAI and the Evolution of Court Processes
</Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            This article explores the
role of JudgeAI in automating judicial processes
through advanced legal algorithms. It discusses
the system's ability to streamline case handling,
provide second opinions for judges, and adapt to
various legal frameworks. The article also looks
at the potential future applications of JudgeAI,
including its use in mediation, regulatory
compliance, and international disputes,
highlighting how it can enhance efficiency,
transparency, and fairness in legal systems.
          </Text>
          <BlogAuthor name="Yuri Kozlov" date={new Date('2024-09-18T18:01:27Z')} />
        </Box>
      </Box>
        </VStack>
      </Box>
    </Grid>
  </ChakraProvider>
  )
}

export default ArticleList